import { useState, useEffect } from 'react';
import './App.css';
import { Chart } from "react-google-charts";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import DisplayBox from './components/DisplayBox';
import LoadingSpinner from './components/LoadingSpinner';

function App() {

  const [isLoading, setIsLoading] = useState(true);
  const [runningUsageCost, setRunningUsageCost] = useState(0);
  const [runningGenCost, setRunningGenCost] = useState(0);
  const [startDate, setStartDate] = useState(() => {
    var baseDate = new Date(new Date().getTime()-(7*24*60*60*1000)) 
    var toMidnight = baseDate.setHours(0,0,0,0);
    return new Date(toMidnight);
  });
  const [endDate, setEndDate] = useState(() => {
    var baseDate =new Date(new Date().getTime()) 
    var toMidnight = baseDate.setHours(0,0,0,0);
    return new Date(toMidnight);
  });
  const [savedStartDate, setSavedStartDate] = useState();
  const [savedEndDate, setSavedEndDate] = useState();
  const [chartData, setChartData] = useState({});  
  const [standingChargeTotal, setStandingChargeTotal] = useState(0);
  
  const options = {
    vAxis: {title:"Amount (pence)"},
    hAxis: {
      title:"Date",
      format: "dd-MMM-yy HH:MM",
      ticks: "dateTicks"
    },
    curveType: "function",
    legend: {position:"bottom"},
    animation: {
      duration: 1000,
      easing: "out",
      startup: true,
    },
  };

  function getData() {
    let newData = [[{type:"datetime", label: "Day"}, "Electricty Used", "Eletricity Generated"]];
    let tariffData = {};
    let electricityUsedData = {};
    let electricityGeneratedData = {};
    let usageTariffData = {};
    let genTariffData = {};
    let usageTariff = [];
    let genTariff = [];
    var sd = startDate.toISOString();
    var ed = endDate.toISOString();

    var timeDiff = endDate.getTime()-startDate.getTime();
    if (timeDiff > -1) {
      var points = timeDiff / (1000 * 60 * 60) * 2;

      var tarHttp = new XMLHttpRequest();
      tarHttp.open("GET", `https://api.octopus.energy/v1/products/${process.env.REACT_APP_USAGE_PRODUCT_CODE}/electricity-tariffs/${process.env.REACT_APP_USAGE_TARIFF_CODE}/standing-charges/` , false);
      tarHttp.setRequestHeader("Authorization", "Basic " + btoa(`${process.env.REACT_APP_OCTOPUS_API_KEY}`) + ":");
      tarHttp.send();	
      tariffData = JSON.parse(tarHttp.response);
      
      setStandingChargeTotal((tariffData.results[0].value_inc_vat) / 48 * points);

      var euHttp = new XMLHttpRequest();
      euHttp.open("GET", `https://api.octopus.energy/v1/electricity-meter-points/2000025971810/meters/19L2020756/consumption?page_size=${points}&period_from=${sd}&period_to=${ed}&order_by=period` , false);
      euHttp.setRequestHeader("Authorization", "Basic " + btoa(`${process.env.REACT_APP_OCTOPUS_API_KEY}`) + ":");
      euHttp.send();	
      electricityUsedData = JSON.parse(euHttp.response);
      
      var egHttp = new XMLHttpRequest();
      egHttp.open("GET", `https://api.octopus.energy/v1/electricity-meter-points/2000060337514/meters/19L2020756/consumption?page_size=${points}&period_from=${sd}&period_to=${ed}&order_by=period` , false);
      egHttp.setRequestHeader("Authorization", "Basic " + btoa(`${process.env.REACT_APP_OCTOPUS_API_KEY}`) + ":");
      egHttp.send();	
      electricityGeneratedData = JSON.parse(egHttp.response);
      
      var pHttp = new XMLHttpRequest();
      pHttp.open("GET", `https://api.octopus.energy/v1/products/${process.env.REACT_APP_USAGE_PRODUCT_CODE}/electricity-tariffs/${process.env.REACT_APP_USAGE_TARIFF_CODE}/standard-unit-rates?page_size=${points}&period_from=${sd}&period_to=${ed}` , false);
      pHttp.setRequestHeader("Authorization", "Basic " + btoa(`${process.env.REACT_APP_OCTOPUS_API_KEY}`) + ":");
      pHttp.send();	
      usageTariffData = JSON.parse(pHttp.response);
      
      var qHttp = new XMLHttpRequest();
      qHttp.open("GET", `https://api.octopus.energy/v1/products/${process.env.REACT_APP_GEN_PRODUCT_CODE}/electricity-tariffs/${process.env.REACT_APP_GEN_TARIFF_CODE}/standard-unit-rates/` , false);
      qHttp.setRequestHeader("Authorization", "Basic " + btoa(`${process.env.REACT_APP_OCTOPUS_API_KEY}`) + ":");
      qHttp.send();	
      genTariffData = JSON.parse(qHttp.response);

      for (let i = 0; i < usageTariffData.results.length; i++){
        usageTariff.push([Date.parse(usageTariffData.results[i].valid_from), usageTariffData.results[i].value_inc_vat])
      }
      for (let i = 0; i < genTariffData.results.length; i++) {
        genTariff.push([Date.parse(genTariffData.results[i].valid_from), Date.parse(genTariffData.results[i].valid_to), genTariffData.results[i].value_inc_vat])
      }

      var tempRunningUsageCost = 0;
      var unitRate = 0;
      for (let i=0; i<electricityGeneratedData.results.length-1; i++) {
        unitRate = usageTariff.find((elem) => elem[0] === Date.parse(electricityUsedData.results[i].interval_start));
        if (unitRate) {
          var t = [electricityUsedData.results[i].interval_start, electricityUsedData.results[i].consumption * -1 * unitRate[1], 0]
          tempRunningUsageCost += t[1];
          newData.push(t);
        }
      }  

      setRunningUsageCost(tempRunningUsageCost);

      var tempRunningGenCost = 0;
      electricityGeneratedData.results.forEach(element => {
        for (let i = 1; i < newData.length -1; i++) {
          if (newData[i][0] === element.interval_start){
            var thisDate = Date.parse(newData[i][0]);

            unitRate = genTariff.find((elem) => {
                return elem[0] < thisDate && (elem[1] > thisDate || isNaN(elem[1]));
            } );

            newData[i][2] = element.consumption * unitRate[2];
            tempRunningGenCost += newData[i][2];
            break;
          }
        }
      })
      setRunningGenCost(tempRunningGenCost);

      newData.slice(1).forEach(x => {
        x[0] = new Date(Date.parse(x[0]))
      });
    }
    return newData;
  }

  useEffect(() => {    
    setIsLoading(true);
  }, [startDate])

  useEffect(() => {    
    setIsLoading(true);

  }, [endDate])

  useEffect(() => {
    var result = getData();
    if (result.length > 1) {
      setSavedStartDate(startDate);
      setSavedEndDate(endDate);
      setChartData(result);
    } else {
      setStartDate(savedStartDate);
      setEndDate(savedEndDate);
      setIsLoading(false);
    }

  }, [isLoading, endDate, savedEndDate, startDate, savedStartDate])

  useEffect(() => {
    setIsLoading(false);

  }, [chartData])

  return (
    <main>
      <div className='title'>⚡ MyEnergy ⚡ </div>
      
      
      <div className='theChart'>
        <div className='chartTitle'>
          <p className='heading'>Energy costings</p>
          <div className="dates">
            <label htmlFor="startDate">Display information from </label>
            <DateTimePicker name="startDate" onChange={setStartDate} value={startDate} disabled={isLoading} />
            <label htmlFor="endDate"> to </label>
            <DateTimePicker name="endDate" onChange={setEndDate} value={endDate} disabled={isLoading} />
          </div>
        </div>
        {isLoading 
          ? <LoadingSpinner /> 
          : <Chart
            chartType={"LineChart"}
            width="100%"
            height="400px"
            data={chartData}
            options={options}
          />
        }
      </div>
      <div className='summary'>
        <DisplayBox
          title="From Grid ⬇"
          value={(runningUsageCost*-1)}
          textColour="red"
        />
        <DisplayBox
          title="Standing Charge"
          value={standingChargeTotal}
          textColour="red"
        />
        <DisplayBox
          title="Exported ⬆"
          value={runningGenCost}
          textColour="green"
        />
        <DisplayBox
          title="Total"
          value={(runningGenCost + runningUsageCost - standingChargeTotal)}
          textColour={(runningGenCost + runningUsageCost - standingChargeTotal) > 0 ? "green" : "red"}
        />
        
        {/* <div className='dataPoints'>
          <label htmlFor="points">If not all data is shown as expected, try increasing this number.<br/>Note, this will have an impact on performance.<br/></label>
          <input 
            className='dataPointsInput'
            placeholder='data points'
            name="points"
            value={tempPoints}
            onChange={handlePointsChange}
          />
        </div> */}
      </div>
      <br/>
      <p className="copyright">
        <a href="https://www.jonnus.co.uk">© Jonnus 2023</a>
      </p>
      
    </main>
    
  );
}

export default App;